.footer-center {
  border-right: 0.5px solid #b1b1b1;
  border-left: 0.5px solid #b1b1b1;
  text-align: center;
}

.footer-left {
  display: flex;

  justify-content: flex-end;
  padding-right: 2%;
}

.footer-left p,
.footer-left h3,
.footer-left h4 {
  text-align: right;
}

.footer-left h3 {
  color: #274c5b;
  font-size: 1.8rem;
  margin-bottom: 12%;
}

.footer-left h4 {
  color: #646464;
  font-size: 1rem;
  font-weight: 500;
}

.footer-left p {
  color: #646464;
  font-weight: 300;
}

.footer-center img {
  margin: auto;
}

.footer-center p {
  text-align: center;
  width: 70%;
  margin: auto;
  font-weight: 300;
}

.footer-center .social-icon {
  padding: 2%;
  background-color: #eff6f1;
  border-radius: 50%;
  margin: 0 1%;
}

.socials {
  margin: 4% auto;
  text-align: center;
}

.footer .certificates {
  margin: 4% auto;
  text-align: center;
}

.footer .certificates img:hover {
  cursor: pointer;
}

.footer .certificates img {
  display: inline;
  width: 16%;
  margin: 0 2%;
}

.footer .notice,
.footer .notice a {
  font-size: 0.8rem;
  font-weight: 300;
  color: #b1b1b1;
}

.footer .notice {
  margin-top: 2%;
}

.footer-right-heading {
  color: #274c5b;
  font-size: 1.8rem;
  margin-bottom: 12%;
}

.footer-right-subheading {
  color: #646464;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 4%;
}

.footer-right-subheading:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footer-right {
  padding-left: 2%;
}

.footer-right-subheading a {
  color: #646464;
  text-decoration: none;
}

@media (max-width: 990px) {
  .footer {
    margin-top: 10%;
  }
  .footer-left {
    justify-content: center;
  }

  .footer-left p,
  .footer-left h3,
  .footer-left h4 {
    text-align: center;
  }

  .footer-center .logo {
    height: 3rem;
    margin-bottom: 16px;
    margin-top: 5%;
  }

  .certificates .iso {
    width: 14% !important;
  }

  .certificates .hipaa {
    width: 20% !important;
  }

  .footer-right {
    margin-top: 4%;
  }

  .footer-right-heading {
    margin-bottom: 4%;
    text-align: center;
  }

  .footer-right-subheading {
    text-align: center;
  }
}

p,
.copyright p {
  color: #646464;
}
