.partner-hero-section {
  width: 100%;
  background-image: url("/public/img/partner.jpg");
  height: 109vh;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  color: #506ea2;
  font-weight: 500;
  font-size: 3rem;
}

h2 {
  color: #646464;
}

.discover {
  margin-top: 1.5rem;
}

.partner-hero-section .container {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(9.5px);
  position: absolute;
  margin-top: 22%;
  width: 40%;
  margin-left: 4%;
}

@media (max-width: 991px) {
  .partner-hero-section .container {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.33);
    backdrop-filter: blur(9.5px);

    margin-top: 26vh;
    width: 90%;
  }
}
