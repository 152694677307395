.app-download {
  padding: 2% 6%;
  margin: auto;
}

.app-download img {
  padding: 8%;
  width: 90%;
}

.app-download-right {
  margin: auto;
  padding: 0 6%;
}

.app-download-heading {
  font-size: 1.8rem;
  font-weight: 500;
  color: #e77c40;
}

.app-download-description {
  font-size: 1.1rem;
  font-weight: 300;
  margin-top: 4%;
}

@media (max-width: 480px) {
  .app-download {
    padding: 10% 4%;
  }
  .app-download .col-md-6 {
    text-align: center;
  }
  .app-download img {
    width: 80%;
  }

  .app-download h2 {
    text-align: center;
    font-size: 1.5rem;
  }
  .app-download p {
    text-align: center;
    font-size: 1rem;
  }

  .app-download button {
    font-size: 1rem !important;
  }
}
