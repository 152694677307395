.div {
  display: flex;

  flex-direction: column;
}
.div-2 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-self: stretch;
  position: relative;
  display: flex;
  min-height: 756px;
  width: 100%;

  padding-right: 20px;
  padding-bottom: 40px;
}
@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
  }
}
.img {
  position: absolute;
  height: 130%;
  width: 130%;
  object-fit: cover;
  object-position: center;
}

.img-3 {
  aspect-ratio: 2;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: center;
  margin-top: 98px;
  max-width: 1247px;
}
@media (max-width: 991px) {
  .img-3 {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    aspect-ratio: 2;
  }
}
.reasons-section-subheading1 {
  width: 80%;
  font-size: 1.1rem;
  text-align: center;
  font-weight: 300;
  color: #646464;
  margin: 4% auto;
}

.reasons-section {
  width: 72%;
  margin: 4% auto;
}

.reasons-section-reasons-list {
  margin: 2% 0;
}

.reasons-section-reason img {
  width: 180px;
  background-color: aliceblue;
  border-radius: 25%;
  padding: 7%;
  height: 180px;
  margin-bottom: 5%;
}

.reasons-section-reason h3 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #646464;
}

.reasons-section-reason {
  text-align: center;
}

@media (max-width: 480px) {
  .reasons-section {
    width: 100% !important;
  }
  .reasons-section-subheading1 {
    width: 98% !important;
  }

  .reasons-section-reason img {
    width: 120px;
    height: 120px;
  }

  .reasons-section-reason h3 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .reasons-section-subheading1 {
    font-size: 1rem;
  }
}
