/* Page Styles */
.thank-you-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}

/* Container Styles */
.thank-you-container {
  text-align: center;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
}

/* Title */
.thank-you-container h1 {
  font-size: 2rem;
  color: #333333;
  margin-bottom: 15px;
}

/* Description */
.thank-you-container p {
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 20px;
}

/* WhatsApp Button */
.whatsapp-button {
  display: inline-block;
  background-color: #25d366;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.whatsapp-button:hover {
  background-color: #1ebe5d;
  transform: scale(1.05);
}
