.head {
  margin-top: 10%;
  margin-bottom: 8%;
}
.head h2 {
  text-align: center;
  color: #27b2f2;
  font-size: 41px;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 480px) {
  .head {
    margin-top: 35%;
    margin-bottom: 15%;
  }
  .head h2 {
    font-size: 30px;
  }
  .table {
    overflow-x: scroll;
  }
}
@media only screen and (min-width: 481px) and (max-width: 920px) {
  .head {
    margin-top: 20%;
  }
}
