.primarySection {
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
  /* Default padding */
  padding-top: 200px;
  /* Adjust this value to offset the navbar height */
}

/* Responsive padding adjustment */
@media (max-width: 768px) {
  .primarySection {
    padding: 10px;
    padding-top: 80px;
    /* Ensure top padding still offsets the navbar on smaller screens */
  }

  .side-bar {
    display: none;
  }
}

h1 {
  /* text-align: center; */
  font-size: 2rem !important;
  margin-bottom: 20px;
  color: #e77c40 !important;
}

.side-bar {
  flex: 1;
  max-width: 300px;
  padding: 20px;
  position: sticky;
  top: 80px;
  /* Offset the navbar height to keep it sticky without overlapping */
}

.main-content {
  flex: 3;
  padding: 20px;
  width: 100%;
}

.grid-main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
}
