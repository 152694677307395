.book-therapy {
  background-color: antiquewhite;
  padding: 0 10%;
  border-radius: 12px;
  height: 22%;
}

.therapists-group-img {
  background-image: url("/public/img/personal-wellness/therapists-group.png");
  background-repeat: no-repeat;
  background-size: cover;

  background-position-y: bottom;
}

.therapists-group-img img {
  display: none;
}

@media (max-width: 480px) {
  .therapists-group-img {
    background: none;
  }
  .therapists-group-img img {
    display: block;
    width: 100%;
  }

  .book-therapy {
    border-radius: 0;
  }
}
