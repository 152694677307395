/* Main Workshop Content */
.workshop-detail-page {
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

/* .flex-container {} */

/* Banner Section */
.workshop-banner img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 20px;
}

/* strike through */
.price-strikethrough {
  text-decoration: line-through;
  color: #999;
  margin-right: 8px;
}

.flex-justified-container {
  display: flex;
  justify-content: space-between;
}

/* Header */
.workshop-detail-page h1 {
  /* text-align: center; */
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.event-date {
  padding-bottom: 10px;
}

.event-date svg {
  margin-right: 5px;
}

/* Sections */
.workshop-section {
  margin-bottom: 25px;
}

.workshop-section h2 {
  font-size: 1.6rem;
  color: #e77c40;
  margin-bottom: 10px;
}

.workshop-section p,
.workshop-section ul {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.workshop-section ul {
  padding-left: 20px;
  list-style-type: disc;
}

.workshop-section ul li {
  margin-bottom: 10px;
}

/* Facilitator Section */
.facilitator-section .facilitator-info {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-top: 15px;
}

.facilitator-info img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.facilitator-info div {
  max-width: 600px;
}

.facilitator-info p {
  margin: 0;
  color: #666;
  line-height: 1.4;
}

/* Call to Action */
.workshop-cta {
  text-align: center;
  margin-top: 30px;
}

.mobile-bar {
  display: none;
}

@media (max-width: 768px) {
  .workshop-cta {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    color: #fff;
    text-align: center;
    padding: 8px 0;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    justify-content: space-around;
  }

  .image-class {
    width: 100px;
    border-radius: 12px;
    margin-bottom: 20px;
  }

  .mobile-bar {
    display: block;
  }
}

.image-class {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 20px;
  object-fit: cover;
  display: block;
}

.register-button {
  padding: 12px 24px;
  font-size: 1rem;
  background-color: #e77c40;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #e77c40;
}