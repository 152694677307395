/* Pricing Section Styles */
.pricing-section {
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

/* Section Heading */
.pricing-section h2 {
  font-size: 2rem;
  color: #333333;
  margin-bottom: 10px;
}

/* Pricing Container */
.pricing-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
  margin: 10px 0;
}

/* Original Price */
.original-price {
  font-size: 1.5rem;
  color: #999999;
}

.strikethrough {
  text-decoration: line-through;
}

/* Current Price */
.current-price {
  font-size: 2rem;
  color: #e77c40;
  font-weight: bold;
}

.highlight {
  background-color: #e77c40;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Limited Time Text */
.limited-time {
  font-size: 1rem;
  color: #e77c40;
  margin-top: 10px;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {

  /* Make everything smaller for phones */
  .pricing-section {
    padding: 15px;
    margin: 10px 0;
  }

  .pricing-section h2 {
    font-size: 1.5rem;
  }

  .pricing-container {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .original-price {
    font-size: 1.2rem;
  }

  .current-price {
    font-size: 1.6rem;
  }

  .limited-time {
    font-size: 0.9rem;
  }
}