.corporate-wellness-why {
  padding: 6% 4%;
  margin: 4% auto;
  background-color: beige;
}

.corporate-wellness-why .container {
  width: 90%;
  margin: auto;
}

.corporate-wellness-why-description {
  font-size: 1.01rem;
  font-weight: 300;
  color: #646464;
  margin-top: 2%;
}

.corporate-wellness-why-area-heading {
  text-align: center;
  color: #646464;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 6px;
}

.corporate-wellness-why-area-subheading {
  text-align: center;
  color: #646464;
  font-size: 0.88rem;
  font-weight: 300;
  margin: 0;
}

.corporate-wellness-why-area {
  text-align: center;
  margin-bottom: 10%;
}

.corporate-wellness-why-areas {
  padding-left: 3%;
}

@media (max-width: 480px) {
  .corporate-wellness-why {
    padding: 10% 4%;
  }

  .corporate-wellness-why .container {
    width: 100% !important;
  }

  .corporate-wellness-why .col-md-5 {
    text-align: center;
  }

  .corporate-wellness-why .col-md-5 button {
    margin-bottom: 12%;
  }
}
