#navbar-custom {
  z-index: 3;
}

.sticky {
  position: fixed;
  background-color: rgb(255, 255, 255) !important;
  -webkit-transition: top 2s;
  transition: top 2s;
  top: 0;
}

.gradient-background {
  background: rgba(247, 247, 247, 0.6) !important;
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.418) 51%,
    rgba(255, 255, 255, 0) 100%
  ) !important; */
}

/* .top {
  background-color: #00000022 !important;
} */

.navLink {
  font-size: 1rem !important;
  margin-right: 0.7em;
}

.my-auto {
  font-size: 1rem;
}

.nav-items {
  display: flex;
  justify-content: space-evenly;
  float: right;
}

.allNavLinks {
  display: flex;
}

.nav-link {
  margin-left: 0.3vw;
  font-size: 1rem;
}

.book-button2 {
  margin-right: 0.6vw;
  padding: 8px 12px;
  color: #646464 !important;
  border-radius: 14px;
  border: #b1b1b1 2px solid !important;
  background-color: white;
  border: 0px;
}

.book-button {
  margin-right: 0.6vw;
  padding: 8px 12px;
  border: #e77c40 2px solid !important;

  background-color: #e77c40;
  border-radius: 14px;
  border: 0px;
}

.navbar-dropdown-heading {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
  color: #646464;
}

.navbar-dropdown-description {
  font-weight: 300;
  font-size: 0.74rem;
  color: #b1b1b1;
}

.navbar-service:hover .navbar-dropdown-description {
  color: #646464;
}

.navbar-arrow-right {
  color: #ebebeb;
  filter: invert(98%) sepia(1%) saturate(89%) hue-rotate(353deg) brightness(116%)
    contrast(84%);
}

.navbar-service:hover .navbar-arrow-right {
  color: #e77c40;
  filter: invert(59%) sepia(7%) saturate(5139%) hue-rotate(334deg) brightness(97%)
    contrast(87%) !important;
}

@media (max-width: 480px) {
  .navbar-brand img {
    width: 7rem !important;
    height: 2.8rem !important;
  }

  .navbar-nav .dropdown-menu {
    padding: 4px 0 !important;
  }
  .nav-background {
    background-color: white;
  }

  .book-button {
    margin-bottom: 2vh;
  }

  .book-button2 {
    margin-bottom: 2vh;
  }

  .navbar-nav button {
    width: fit-content;
    margin-top: 2%;
  }
}

.bookAppointmentNavLink {
  color: white;
  text-decoration: none;
}

.bookAppointmentNavLink:hover {
  color: #646464 !important;
  text-decoration: none !important;
}

.book-button:hover {
  color: white !important;
  background-color: #e77c40;
}

.book-button2:hover {
  color: #646464 !important;
  background-color: white;
}

.dropdown-item {
  padding: 0 !important;
}
