/* @tailwind base;
@tailwind components;
@tailwind utilities; */

* {
  padding: 0px 0px;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  color: #646464;
}

div,
p {
  color: #646464;
}

.bodyView {
  margin-top: -10%;
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .bodyView {
    margin-top: -20%;
    overflow-x: hidden;
  }
}

.navbar-hero {
  height: 105vh;
  background-color: #ffffff;
}
@media (max-width: 991px) {
  .navbar-hero {
    height: 58vh;
  }
}

button:hover,
.nav-link:hover {
  text-decoration: underline;
}

.dropdown-menu {
  padding: 20% !important;
}
