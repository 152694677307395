.hipa {
  margin-top: 10%;
  margin-bottom: 5%;
}
.hipa h2 {
  font-size: 55px;
  font-weight: 500;
  color: #0daffa;
  text-align: center;
}
.section {
  margin: auto;
  width: 80%;
  margin-top: 5%;
}
.Adata {
  color: rgb(208, 41, 26);
  font-size: 18px;
  font-weight: 300;
  background-color: rgb(211, 240, 246);
  border: none;
}
.Ahead button:not(.collapsed) {
  color: black;
  background-color: rgb(248, 165, 12);
  font-size: 25px;
  font-weight: 400;
  margin: auto;
  border: none;
}
.Ahead h3 {
  font-size: 25px;
  margin: auto;
  color: #fff;
  text-align: center;
}
.pp {
  color: rgb(53, 111, 35);
  font-weight: 500;
  text-align: center;
  font-size: 30px;
}
ul.point {
  list-style-type: disclosure-closed;
}
.Adata p {
  color: red;
  letter-spacing: 0.5px;
  font-weight: 400;
}
.formhead {
  margin-top: 3rem;
}
.form {
  background-color: #fafa49;
  margin: auto;
  width: 70%;
  border-radius: 20px;
  border: 2px solid #0daffa;
  padding: 1rem;
}
.form p {
  font-size: 22px;
  color: black;
}
@media only screen and (max-width: 480px) {
  .section {
    width: 90%;
  }
  .Ahead button:not(.collapsed) {
    font-size: 12px;
    margin: auto;
  }
  .Adata {
    font-size: 12px;
  }

  .form {
    width: 90%;
  }
  .form p {
    font-size: 15px;
    padding: 0;
  }
  .head h3 {
    font-size: 20px;
    color: #0daffa;
    text-align: center;
  }
  .hipa h2 {
    font-size: 35px;
    font-weight: 500;
    color: #0daffa;
    text-align: center;
  }
  .hipa {
    margin-top: 30%;
    margin-bottom: 10%;
  }
}
