.carousel-root {
  /* width: 64% !important; */
  /* margin: auto !important; */
  /* margin-top: 3% !important; */
  background-color: transparent;
}

.carousel .slide {
  color: black;
  /* height: 100%; */
}

.caraousel-1 {
  display: flex;
  align-items: center;
  gap: 3vw;
}

.star {
  position: relative;

  display: inline-block;

  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;

  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.3em solid transparent;

  /* Controlls the size of the stars. */
  font-size: 14px;
}
.star:before,
.star:after {
  content: "";

  display: block;
  width: 0;
  height: 0;

  position: absolute;
  top: 0.6em;
  left: -1em;

  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 1em solid transparent;

  transform: rotate(-35deg);
}

.star:after {
  transform: rotate(35deg);
}

.avatar {
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border: 5px white solid;
  border-radius: 50%;
  overflow: hidden;
  /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
  /* transform: translatey(0px); */
  animation: float 6s ease-in-out infinite;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  margin-top: 6%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 300 !important;

  line-height: 29px !important;
  color: #222;
  font-size: 17px;
  font-family: sans-serif;
}

.review {
  background-color: white;

  padding: 8%;
  margin-bottom: 2vw;
  border-radius: 44px 80px 70px 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.owner-1 h4 {
  color: #666363;
  font-size: 18;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  font-weight: 500;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-weight: 500;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: black !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: transparent !important;
  color: #000;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.carousel-slider button {
  background-color: red;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 16.5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .avatar {
    height: 100%;
  }

  .carousel .slide img {
    width: 100% !important;
    border-radius: 50%;
  }

  .carousel .control-dots {
    bottom: 0% !important;
  }
}
