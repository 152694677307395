.statistics {
  margin: 6% auto 6% auto;
}

.statistics h2 {
  text-align: center;
  margin-bottom: 4%;
}

.therapy-stats {
  margin: auto;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: #646464;
}

.therapy-stats-description {
  text-align: center;
  font-weight: 300;
}
