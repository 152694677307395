.testimonial {
  margin: 0vh auto;
  padding: 0vh 2vh;
}

.corporateTestimonial {
  background-color: #fff;
  padding-top: 2rem;
}

.corporateTestimonial .heading span {
  background: linear-gradient(to right, #fcd107 0%, #e77c40 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.corporateTestimonial .subheading {
  font-size: 14px;
  letter-spacing: 16px;
  font-weight: 200;
  text-align: center;

  margin: 0;
  margin-bottom: 4px;
}

.corporateTestimonial .heading {
  margin-bottom: 1.5rem;
  letter-spacing: 0.5px;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
}

.testimonial h2 {
  font-size: 2rem;
  letter-spacing: 1.2px;
}

.testimonialCarousel {
  padding: 2vh 0vh;
  margin-top: 0vh;
}

.testimonialCarousel p {
  background: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to right, #fcd207, #e77c40) border-box;
  border: 4px solid transparent;
  padding: 6vh 3vh;
  border-radius: 2vh;
  text-align: center;
  font-weight: 300;
  color: #646464;
  letter-spacing: 1px;
}

.testimonialCarousel img {
  position: absolute;
  top: 0vh;
  left: 0vh;
  width: 6vh;
}

.testimonialOwner {
  text-align: center;
}

.testimonialOwner span {
  font-weight: 200;
}

@media only screen and (min-width: 600px) {
  .testimonialCarousel {
    padding: 2vh 8vh;
  }
  .testimonial h2 {
    font-size: 2.8rem;
  }
  .testimonial {
    padding: 0 2vh;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 720px) {
  .testimonialCarousel {
    padding: 2vh 8vh;
  }
  .testimonialCarousel {
    margin-top: 4vh;
  }
  .testimonialCarousel img {
    top: 3vh;
    left: 10vh;
    width: 10vh;
  }
  .testimonialCarousel p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1240px) {
  .testimonialCarousel {
    padding: 2vh 14vh;
  }
}

@media only screen and (min-width: 900px) {
  .testimonialCarousel {
    padding: 2vh 12vh;
  }
}

@media (max-width: 480px) {
  .corporateTestimonial .heading {
    font-size: 2.1rem;
  }

  .corporateTestimonial .subheading {
    letter-spacing: 12px;
  }

  .corporateTestimonial .carousel-control-prev {
    left: -12%;
  }

  .corporateTestimonial .carousel-control-next {
    right: -12%;
  }

  .corporateTestimonial .testimonialCarousel p {
    padding: 4vh 2vh !important;
    font-size: 0.94rem !important;
  }
}
