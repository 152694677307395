.cw-services {
  margin-top: 4%;
}

.services-carousel {
  background-color: rgba(255, 0, 255, 0.2);
  left: 10px;
  top: 10px;
  width: calc(100% - 200px);
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
}

.services-feature-card {
  width: 50vh;
  display: inline-block;
  padding: 7%;
  text-align: center;
}

.services-feature-card img {
  width: 64%;
}

.feature-card-arrow {
  filter: invert(42%) sepia(0%) saturate(236%) hue-rotate(210deg) brightness(91%) contrast(92%);
  width: 3.2rem;
  cursor: pointer;
}

.feature-card-heading {
  font-size: 1.5rem;
  font-weight: 500;
  color: #646464;
  margin-top: 8%;
}

.feature-card-subheading {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 2%;
  color: #646464;
}
