.main-container {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    border-radius: 22px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    background-color: #fff;
    transition: transform 0.2s ease-in-out;
}

.main-container:hover {
    transform: scale(1.02);
}

.main-container img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 15px;
    text-align: center;
}

.card-title {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #333;
}

.card-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
}

.card-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #e77c40;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card-button:hover {
    background-color: #e77c40;
}

/* Badge styling */
.badge {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    z-index: 1;
}

.date-badge {
    top: 10px;
    left: 10px;
}

.registration-badge {
    top: 10px;
    right: 10px;
}

/* Responsive adjustment for smaller screens */
@media (max-width: 768px) {
    .main-container {
        max-width: 100%;
    }
}
