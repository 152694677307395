.logoSlider .slider {
  background: transparent;
  height: 180pt;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  cursor: default;
  user-select: auto;
  /* touch-action: pan-y !important; */
  margin-top: 5em;
}

.ourClients {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  color: #646464;
  font-size: 45px;
  font-weight: 500;
}

.logoSlider .slider:before {
  left: 0;
  top: 0;
}
.logoSlider .slider:after,
.logoSlider .slider:before {
  content: "";
  height: 75pt;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.logoSlider .slider:after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.logoSlider .slider:after,
.logoSlider .slider:before {
  content: "";
  height: 75pt;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.logoSlider .slider .slide-track-1 {
  animation: scroll-l 30s linear infinite;
  display: flex;
  width: 3500px;
}
.logoSlider .slider .slide-track-1:hover {
  animation-play-state: paused;
  display: flex;
  width: 3500px;
}

.logoSlider .slider .slide-track-2 {
  animation: scroll-r 30s linear infinite;
  display: flex;
  width: 3500px;
}

.logoSlider .slider .slide {
  height: 75pt;
  width: 250px;
  display: flex;
  align-items: center;
  text-align: center;
}
.logoSlider .slider .slide img {
  width: 180px;
  padding: 1pc;
  vertical-align: middle;
  margin: 0 auto;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

@keyframes scroll-l {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
@keyframes scroll-r {
  100% {
    transform: translateX(0);
  }
  0% {
    transform: translateX(calc(-250px * 7));
  }
}
