.corporate-wellness-hero-section {
  width: 100%;
  background-image: url("/public/img/cw-background.jpg");
  height: 107vh;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  color: #506ea2;
  font-weight: 500;
  font-size: 3rem;
}

h2 {
  color: #646464;
}

.discover {
  margin-top: 1.5rem;
}

.corporate-wellness-hero-section .container {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.685);
  backdrop-filter: blur(9.5px);
  position: absolute;
  margin-top: 20%;
  width: 38%;
  padding: 2%;
  right: 0;
  margin-right: 4%;
}

@media (max-width: 480px) {
  .corporate-wellness-hero-section {
    background-size: contain;
    height: 560px;
    margin-top: 20%;
  }

  .corporate-wellness-hero-section .container {
    margin-top: 20%;
    width: 90%;
    left: 0%;
    
    text-align: center;
    margin: auto;
    margin-top:35vh;
    padding: 4% 4%;
    background: rgba(238, 235, 235, 0.67);
  }

  .corporate-wellness-hero-section .div-9 {
    margin-top: 4% !important;
  }

  .corporate-wellness-hero-section h1 {
    font-size: 2.1rem;
  }
  .corporate-wellness-hero-section h2 {
    font-weight: 400;
    font-size: 1.3rem;
  }

  .corporate-wellness-hero-section button {
    font-size: 16px;
    margin-top: 12px;
  }

  .corporate-wellness-hero-section .subheading {
    font-size: 16px;
  }
}
