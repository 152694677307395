.subheading {
  font-size: 1.1rem;
}

.features {
  margin: 6% auto;
}

.features-subheading {
  width: 60%;
  font-size: 1.1rem;
  font-weight: 300;
  margin: 4% auto;
}

.features-heading {
  font-size: 2rem;
  text-align: center;
  color: #e77c40;
}

.feature-list {
  margin: 4% auto;
  padding: 0 16%;
}

.feature-list h3 {
  margin-bottom: 12px;
}

.feature-item-image {
  padding: 0 8%;
}

.feature-item-description {
  font-size: 1.1rem;
  font-weight: 300;
}

.features .feature-list-mobile {
  display: none;
}

@media (max-width: 480px) {
  .features .features-subheading {
    width: 90%;
    font-size: 1rem;
    margin-top:8vh;
  }

  .features .features-heading {
    font-size: 1.4rem;
    margin-top: 12%;
  }

  .features .feature-list {
    padding: 0 4%;
  }

  .features .feature-item {
    padding-bottom: 16%;
  }

  .features .feature-list-mobile {
    display: block;
  }
  .features .feature-list-desktop {
    display: none;
  }

  .features .feature-item img {
    padding: 0 16%;
  }
}
