.how-section-heading {
  text-align: center;
  color: #646464;
  font-weight: 500;
  font-size: 2.4rem;
}

.divider {
  border-top: 2px #646464;
  border-top-style: dashed;
  margin: 6% 0;
}

.how-section-access,
.how-section-discover,
.how-section-engagement,
.how-section-help {
  width: 80%;
  margin: 4% auto;
}

.how-section-subheading {
  color: #646464 !important;
  font-size: 1.8rem;
  font-weight: 500;
}

.how-section h3 {
  color: #646464;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 2%;
}

.how-section p {
  color: #646464;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 6%;
}

.gradient-text {
  background: linear-gradient(to right, #fcd107 0%, #e77c40 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.how-section-image {
  text-align: center;
}

.how-section-image img {
  width: 70%;
  padding: 4%;
}

.how-section-image-mobile {
  display: none;
}

@media (max-width: 480px) {
  .how-section-heading {
    font-size: 2rem;
  }

  .how-section-access,
  .how-section-discover,
  .how-section-engagement,
  .how-section-help {
    width: 100%;
  }

  .how-section-image img {
    width: 85%;
  }

  .how-section-image-mobile {
    display: block;
  }
  .how-section-image-desktop {
    display: none;
  }
}
