/* Countdown Timer Container */
.countdown-timer {
  text-align: center;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  /* max-width: 600px; */
  position: relative;
}

/* Workshop Title */
.workshop-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

/* Event Date and Time */
.event-date-time {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

.padding-right {
  margin-right: 5px;
}

/* Timer Display */
.timer {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.time-box {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* width: 70px; */
}

.time-box span {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: #e77c40;
}

.time-box p {
  font-size: 0.9rem;
  color: #888;
  margin-top: 5px;
}

/* Call-to-Action Button */
.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #e77c40;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e77c40;
}

/* Registrants Info */
.registrants-info {
  margin-top: 15px;
  font-size: 1rem;
  color: #444;
}

/* Responsive Design */
@media (max-width: 768px) {
  .countdown-timer {
    padding: 20px;
  }

  .workshop-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }

  .time-box {
    /* width: 50px; */
    padding: 10px;
  }

  .time-box span {
    font-size: 1.2rem;
  }

  .cta-button {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
}