.discover {
  background-color: #fcece8;
  padding: 4% 10%;
}

.discover-header span {
  color: rgb(248, 127, 87);
}

.discover-content-heading span {
  color: rgb(248, 127, 87);
}

.discover-header {
  text-align: center;
}

.discover-subheading {
  text-align: center;
  margin-top: 12px;
}

.dicover-icon {
  width: 12vh;
}

.discover-type {
  font-size: 1.4rem;
  margin: auto;
  font-weight: 300;
  font-weight: 500;
  color: #646464;
}

.discover-description {
  margin-top: 2%;
}

.discover-content-heading {
  font-size: 1.5rem;
  margin: auto;
  margin-top: 6%;
  font-weight: 500;
  color: #646464;
  text-align: center;
}

.dicover-content-icon {
  width: 6vh;
}
.rhap_container {
  width: 32% !important;
  margin: auto !important;
  margin-top: 4% !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 3% 2% !important;
  border: rgb(255, 158, 55) 2px solid;
}

.dicover-audio-heading {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 8%;
}

.rhap_volume-controls,
.rhap_additional-controls {
  display: none !important;
}

.rhap_controls-section {
  justify-content: center !important;
}

@media (max-width: 480px) {
  .discover {
    margin-top: 10% !important;
    padding: 12% 4%;
  }

  .discover .dicover-icon {
    width: 8vh !important;
  }

  .discover .col-md-4 {
    margin-bottom: 4%;
    margin-top: 2%;
  }

  .discover .discover-item-suggestion {
    width: 100% !important;
    margin: 8% auto !important;
  }

  .discover .discover-item-suggestion div {
    margin-bottom: 2%;
  }

  .rhap_container {
    width: 100% !important;
    border-radius: 12px !important;
    border: rgb(255, 158, 55) 1px solid;
  }
  .discover-main-points {
    padding: 0 2%;
  }

  .discover-header {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .discover-subheading {
    font-size: 0.98rem;
  }
}
