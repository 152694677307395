/* General Styles */
body {
  font-family: Arial, sans-serif;
  background: #f9f9f9;
  margin: 0;
  padding: 0;
}

/* Main Container */
.main-container-form {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
  font-size: 1.5em;
}

/* Form Styles */
.registration-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-size: 0.9em;
  color: #555555;
}

input {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1em;
}

input:focus {
  border-color: #e77c40;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
}

/* Button Styles */
.submit-button {
  padding: 10px;
  background-color: #e77c40;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #874017;
}

/* Loader Styles */
.loader-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.circular-loader {
  width: 24px;
  height: 24px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e77c40;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 500px) {
  .main-container {
    margin: 20px;
    padding: 15px;
  }

  .form-title {
    font-size: 1.2em;
  }
}
