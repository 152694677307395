.pss {
    /* display: flex; */
    flex-wrap: wrap;
    padding: 50px;
    /* Default padding */
    padding-top: 200px;
    /* Adjust this value to offset the navbar height */
}

/* Responsive padding adjustment */
@media (max-width: 768px) {
    .pss {
        padding: 10px;
        padding-top: 80px;
        /* Ensure top padding still offsets the navbar on smaller screens */
    }
}