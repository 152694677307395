.personal-wellness-hero-section {
  width: 100%;
  background-image: url("/public/img/personal-wellness/hero-section.png");
  height: 107vh;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  color: #506ea2;
  font-weight: 500;
  font-size: 3rem;
}

h2 {
  color: #646464;
}

.discover {
  margin-top: 1.5rem;
}

.personal-wellness-hero-section .container {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.685);
  backdrop-filter: blur(9.5px);
  position: absolute;
  margin-top: 20%;
  width: 38%;
  left: 0;
  padding: 2%;
  margin-left: 4%;
}

.personal-wellness-hero-section .subheading {
  color: #646464;
}

@media (max-width: 480px) {
  .personal-wellness-hero-section {
    background-size: contain;
    height: 500px;
    margin-top: 20%;
  }

  .personal-wellness-hero-section .container {
    margin-top: 20%;
    width: 90%;
    left: 4%;
    
    text-align: center;
    margin: auto;
    margin-top: 30vh;
    margin-bottom:5vh;
    background: rgba(238, 235, 235, 0.67);
  }

  .personal-wellness-hero-section h1 {
    font-size: 2.2rem;
  }
  .personal-wellness-hero-section h2 {
    font-weight: 400;
    font-size: 1.4rem;
  }

  .personal-wellness-hero-section button {
    font-size: 16px;
    margin-top: 12px;
  }

  .personal-wellness-hero-section .subheading {
    font-size: 16px;
  }
}
