.institute {
  margin: 0% 8%;
}

.institute img {
  margin-bottom: 2vh;
  width: 80%;
}

.desktopView {
  display: none;
}

.mobileView {
  display: block;
}

@media only screen and (min-width: 600px) {
  .institute {
    width: 90%;
    margin-top: 16vh;
  }
  .institute img {
    width: 72%;
  }
  .instituteDescription {
    font-size: 20px !important;
    margin: 4vh 0;
  }
}

@media only screen and (min-width: 900px) {
  .institute {
    margin-top: 8%;
  }
  .institute img {
    margin-top: 0vh;
    width: 100%;
  }
  .instituteDescription {
    font-size: 20px !important;
    margin: 6vh 0;
  }
  .desktopView {
    display: block;
  }
  .mobileView {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .institute {
    margin: 0px auto;

    margin-top: 3.7vh;
    width: 98%;
  }
  .subheading {
    margin-bottom: 12px !important;
  }
  .institute img {
    width: 200px !important;
    height: 200px !important;
  }
  .experienceImageParent {
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }

  .experience h4 {
    margin-top: 12px;
  }
  .heading {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 4%;
  }
}

.experience h4 {
  text-align: center;
  font-size: 18px;
}

.experience p {
  text-align: center;
  font-weight: 200;
  font-size: 16px;
}

.experience div {
  padding: 28px;
}

.experienceImage {
  border-radius: 50%;
}

.description {
  font-weight: 200;
  font-size: 18px;
}

.heading {
  margin-bottom: 1.5rem;
  color: var(--text);
  font-size: 38px;
  font-weight: 500;
}

.instituteDescription {
  font-size: 18px;
  font-weight: 300;
  margin: 4vh 0;
}

.institute .interestedButton {
  color: #646464 !important;
  border-radius: 12px !important;
  border-color: #646464 !important;
  padding: 10px 24px !important;
}

.institute .interestedButton:hover {
  background-color: #f2e97c !important;
  color: white !important;
  border-color: #f2e97c !important;
}

.horizonalSlider {
  display: grid;
  grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: repeat(6, calc(50% - var(--gutter) * 2));
  grid-template-rows: minmax(150px, 1fr);

  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(0.75 * var(--gutter));
  margin-bottom: calc(-0.25 * var(--gutter));
  list-style: none;
  padding: 0;
}

.horizontalSliderContainer {
  width: 100%;
  height: 667px;
  background: #dbd0bc;
  overflow-y: scroll;
}

.horizonalSlider li {
  scroll-snap-align: center;
  padding: calc(var(--gutter) / 2 * 1.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
}

.cardCarousel {
  margin-top: 8vh;
}

.navigationIconRight {
  position: absolute;
  top: 50%;
  right: 0;
}

.navigationIconLeft {
  position: absolute;
  top: 50%;
  left: 0;
}
