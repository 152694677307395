/* eslint-disable */


.hero {
  display: flex;
  justify-content: space-between;
  gap: 5vw;
}





/* title container */

.outflow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: white;
 
  padding: 3em 6em 4em 4vw;
  margin-left: 10vw;

}

.titleContainer h2 {
  margin-bottom: 0.2vw;
  color: #ef7505;
  font-weight: bold;
  font-size: 44px;
}

.signupButton {
  background-color: #e77c40;
  color: white;
  font-size: 21px;
  padding-right: 1.2vw;
  padding-left: 1.2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  margin-right: 1vw;
}

.downloadButton {
  font-size: 21px;

  padding-right: 1.2vw;
  padding-left: 1.2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  margin-right: 1vw;
  border-color: #383838;
  color: #383838;
}

.titleContainer span {
  font-size: 24px;
  color: #646464;
}




@media (max-width: 991px) {
  .hero {
    display: flex;
    flex-direction: column;

    margin-top: -80px;
    
   
  }
  

  .titleContainer {
    
    margin-top: 980px;
    margin-left: 10vw;
    margin-right: 10vw;
   
    padding:5vw;
    gap:1.4vh;
    
  }

.titleContainer h2 {

  font-size: 32px;
}

.signupButton {
  
  font-size: 19px;
  padding-right: 1.8vw;
  padding-left: 1.8vw;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  
  width:60vw;
}
.downloadButton {
  font-size: 19px;
  font-weight: 400;

  padding-right: 1.8vw;
  padding-left: 1.8vw;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  border-color: #383838;
  color: #383838;
  width:60vw;
  border-width: 1.5px;
}

.titleContainer span {
  font-size: 18px;
  color: #646464;
}



  .leftImage-background img,
  .leftImage-background,
  .leftSide,
  .rightImage-background,
  .rightImage-background img,
  .rightSide {
    visibility: hidden;
  }
}
