.app-download-modal {
  padding: 0% 4% 4% 4%;
}

.app-download-modal-left img {
  width: 100%;
}

.appStore {
  width: 20vh !important;
  height: 6vh !important;
  margin-bottom: 5%;
  cursor: pointer;
}

.download {
  margin-top: 2rem;
}

.app-download-modal-right p {
  margin: 2% 0 16% 0;
  text-align: center;
}

.app-download-modal-right h2 {
  text-align: center;
}
.app-download-modal-right {
  margin: auto;
}

@media (max-width: 480px) {
  .app-download-modal-left img {
    width: 60% !important;
  }
  .app-download-modal-left {
    text-align: center;
  }
  .app-download-modal-qr {
    display: none;
  }
}
