.contact-us-modal-heading {
  text-align: center;
  font-size: 2.2rem;
}

.contact-us-modal-subheading {
  text-align: center;
  font-size: 1rem;
  font-weight: 300;

  width: 70%;
  margin: auto;
  margin-bottom: 4% !important;
  margin-top: 2% !important;
}
