.newsletter {
  padding: 2.5% 8%;
  background-color: #fcece8;
  margin-bottom: 8%;
  margin-top: 4%;
}

.newsletter img {
  width: 90%;
}

.newsletter .form-outline {
  margin: auto;
}

.newsletter-heading {
  font-size: 1.6rem;
  font-weight: 500;
  color: #646464;
}

.newsletter-subheading {
  color: #646464;
  font-size: 14.3px;
  margin-top: 3%;
  font-weight: 300;
}

@media (max-width: 480px) {
  .newsletter .subscribe-button {
    margin: 4% auto !important;
    text-align: center !important;
  }

  .newsletter .col-md-3 {
    text-align: center;
  }

  .newsletter img {
    width: 70%;
  }

  .newsletter {
    padding: 5% 4%;
  }

  .newsletter h2,
  .newsletter p {
    text-align: center;
  }

  .email-input div {
    width: 100%;
  }

  .newsletter button {
    height: 42px !important;
  }
}
