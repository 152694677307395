.community-header {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
}

.community-info {
  width: 84%;
  margin: auto;
}

.community {
  margin-top: 8%;
  margin-bottom: 8%;
}

.community-subheader {
  margin: 1% 20%;
  text-align: center;
}

.community-row {
  width: 70%;
  margin: 4% auto;
}

.community-row img {
  width: 100%;
}

.community-row h3 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #646464;
}

.community-row p {
  font-size: 1rem;
  font-weight: 300;
  color: #646464;
}

.community-info-heading {
  font-size: 1rem;
  font-weight: 400;
  color: #646464;
  text-align: center;
  margin-top: 2%;
}

.community-info-box-inner {
  background-color: beige;
  padding: 6% 4%;
  display: grid;
  border-radius: 12px;
}

.community-info-box img {
  width: 12vh;
  padding: 4%;
  margin: auto;
  text-align: center;
}

@media (max-width: 480px) {
  #explore-sw .div-14 {
    font-size: 2rem !important;
  }

  #explore-sw .cardCaraousel .carousel__wrapper {
    height: 48vh;
  }

  #explore-sw .cardCaraousel {
    height: 64vh;
  }

  .community-subheader {
    margin: 1% 2%;
  }

  .community {
    margin: 16% auto !important;
  }
  .community-row {
    width: 100%;
  }
  .community-row-first {
    margin-top: 10%;
  }
  .community-row h3 {
    font-size: 1.2rem;
  }

  .community-info {
    width: 100%;
    margin-top: 10%;
  }
  .community-info-box {
    margin-bottom: 4%;
  }
}
