.testimonials:hover {
  cursor: pointer;
}

.testimonials {
  margin: auto;
  padding: 4% 0;
  background-color: blanchedalmond;
}

.testimonial-heading {
  font-size: 2.5rem;
  font-weight: 600;
  color: #646464;
}

.testimonial-heading span {
  color: #e77c40;
}

.testimonial-heading p {
  font-size: 1rem;
  margin-top: 12px;
  font-weight: 300;
}

.testimonial-image {
  padding: 1%;
  background-color: white;
  position: absolute;
  border-radius: 50%;
}

.testimonial-left {
  display: flex;
  justify-content: center;
  margin: auto;
}

.testimonial-image img {
  width: 40px;
}

.testimonial-text {
  background-image: url("/public/img/personal-wellness/shape.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 45vh;
  width: 58%;
  text-align: center;
  display: flex;
}

.testimonial-text p {
  width: 80%;
  color: white;
  text-align: center;
  margin: auto;
  font-size: 0.89rem;
}

.testimonials .arrow {
  padding: 1%;
  background-color: white;
  border-radius: 50%;
  height: 40px;
  margin: auto;
}

@media (max-width: 480px) {
  .testimonials {
    padding: 10% 1%;
  }
  .testimonial-heading {
    font-size: 1.5rem;
  }
  .testimonial-text {
    height: 280px;
    width: 100%;
  }
  .testimonial-text p {
    font-size: 0.8rem;
    width: 90%;
  }
  .testimonials .arrow {
    height: 26px;
  }
  .testimonial-right {
    margin-top: 4%;
  }
}
