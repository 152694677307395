.corporate-wellness-services {
  width: 86%;
  margin: 4% auto;
}

.corporate-wellness-services-heading {
  text-align: center;
  color: #646464;
  font-weight: 500;
  font-size: 2.4rem;
}

.corporate-wellness-services-subheading {
  font-size: 1.1rem;
  font-weight: 300;
  color: #646464;
  width: 80%;
  text-align: center;
  margin: 1% auto;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.react-horizontal-scrolling-menu--scroll-container {
  scrollbar-width: none;
}

@media (max-width: 480px) {
  .corporate-wellness-services {
    width: 100%;
    margin-top: 16%;
  }
  .corporate-wellness-services-heading {
    font-size: 2rem;
  }
  .corporate-wellness-services-subheading {
    width: 100%;
    font-size: 1rem;
  }
  .react-horizontal-scrolling-menu--arrow-left {
    width: 32px !important;
  }
  .react-horizontal-scrolling-menu--arrow-right {
    width: 32px !important;
  }
  .services-feature-card {
    width: 300px !important;
  }
  .feature-card-heading {
    font-size: 1.3rem !important;
  }
}
