.Termsec {
  margin: 9% auto;
  width: 90%;
  align-items: center;
}
.Termsec h1 {
  color: #27b2f2;
  font-size: 41px;
  margin-bottom: 2rem;
}
.Termsec h2,
.Termsec h3 {
  color: #27b2f2;
}

@media only screen and (max-width: 480px) {
  .Termsec {
    margin-top: 40%;
    margin-bottom: 15%;
  }
  .Termsec h1 {
    font-size: 31px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 920px) {
  .Termsec {
    margin-top: 20%;
  }
  .Termsec h3 {
    font-size: 35px;
  }
}
