.flex-spaceEvenly-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
}

.gap-row{
    padding-left: 50px;
}

.gap-col{
    padding-top: 20px;
}

.flex-spaceEvenly-col{
    display: flex;
    flex-direction: column;
}

.clear-button {
    padding: 5px 12px;
    border: #e77c40 2px solid;
    background-color: #e77c40;
    border-radius: 14px;
    color: white;
  }

.clear-button:hover {
    color: white !important;
    border: #e77c40 2px solid;
    background-color: #e77d407a;
  }

  .clear-button:focus-visible{
    background-color: #e77c40;
    border: #e77c40 2px solid;
    box-shadow: 0 0 0 0.25rem #d6baa9;
  }

  .clear-button:active{
    background-color: #e77c40 !important;
    border: #d6baa9 2px solid !important;
  }

  .clear-button:disabled{
    color: white !important;
    background-color: #6b6b6b;
    border: #6b6b6b 2px solid;
  }

  .filter-heading{
    color: #646464 !important;
  }