.breadcrumbs {
    font-size: 0.9rem;
    padding: 10px;
    display: flex;
    align-items: center;
}

.breadcrumb-separator {
    margin: 0 5px;
    color: #aaa;
}

.breadcrumbs a {
    color: #e77c40;
    text-decoration: none;
}

.breadcrumbs a:hover {
    text-decoration: underline;
}