/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Heading */
.popup-content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

/* Form Styles */
.form-group {
  margin-bottom: 15px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit Button */
.submit-button {
  padding: 12px 20px;
  font-size: 1rem;
  background-color: #e77c40;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  text-align: center;
}

.submit-button:hover {
  background-color: #e77c40;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
}

/* Loader Container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height, or customize based on context */
  width: 100%; /* Full width, or customize based on context */
}

/* Circular Loader */
.circular-loader {
  width: 20px;
  height: 20px;
  border: 4px solid black; /* Outer border */
  border-top: 4px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
